import { render, staticRenderFns } from "./ChapelRF.vue?vue&type=template&id=65648d3a&"
import script from "./ChapelRF.vue?vue&type=script&lang=js&"
export * from "./ChapelRF.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports